import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalAppConfig } from '../../config/GlobalAppConfig';
import { GetAlertsPendingCountResponse, Productivity } from '../interfaces/productivity.interface';

@Injectable({
  providedIn: 'root'
})
export class ProductivityService {

  private baseApiUrl = GlobalAppConfig.getBaseApiContext();

  private _publlicationData: Productivity.PublicationData = null;
  private _thesisData: Productivity.ThesisData = null;
  private _bookData: Productivity.BooksData = null;
  private _presentationData: Productivity.PresentationData = null;
  private _creationData: Productivity.CreationData = null;

  constructor(private http: HttpClient) { }

  get PublicationInformation() {
    return this._publlicationData ;
  }

  public updatePublicationInformation(publlicationData: Productivity.PublicationData) {
    this._publlicationData = publlicationData;
  }

  get ThesisInformation() {
    return this._thesisData ;
  }

  public updateThesisInformation(thesisData: Productivity.ThesisData) {
    this._thesisData = thesisData;
  }

  get BookInformation() {
    return this._bookData ;
  }

  public updateBookInformation(bookData: Productivity.BooksData) {
    this._bookData = bookData;
  }

  get PresentationInformation() {
    return this._presentationData ;
  }

  public updatePresentationInformation(presentationData: Productivity.PresentationData) {
    this._presentationData = presentationData;
  }

  get CreationInformation() {
    return this._creationData ;
  }

  public updateCreationInformation(creationData: Productivity.CreationData) {
    this._creationData = creationData;
  }

  public getAuthorshipTypes() {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .get<Productivity.PublicationListResponse>(`${this.baseApiUrl}/productivity/magazine/authorship-types`
      , { headers });
  }

  public getPublicationTypes() {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .get<Productivity.PublicationListResponse>(`${this.baseApiUrl}/productivity/magazine/publication-types`
      , { headers });
  }

  public updatePublicationState(request: Object) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<any>(`${this.baseApiUrl}/productivity/magazine-publication/update-status`
      , request, { headers });
  }

  public updatePublicationPointState(statusId: number, publicationId: number) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<any>(`${this.baseApiUrl}/productivity/magazine-publication/update-point-status?idPublication=${publicationId}&idNewStatus=${statusId}`
      , null, { headers });
  }


  public approveMagazine(magazineId: number) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<any>(`${this.baseApiUrl}/productivity/magazine/approve-magazine?magazineId=${magazineId}`
      , null, { headers });
  }

  public updateMagazine(request: any) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<any>(`${this.baseApiUrl}/productivity/magazine/update-magazine`
      , request, { headers });
  }

  public getAllMagazines() {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .get<Productivity.GetMagazinesResponse>(`${this.baseApiUrl}/productivity/magazine/all-magazines`
      , { headers });
  }

  public getAllDetailedMagazines(page: string, size: string) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .get<Productivity.GetMagazinesDetailResponse>(`${this.baseApiUrl}/productivity/magazine/all-detailed-magazines?page=${page}&size=${size}`
      , { headers });
  }

  public getAllPublicationStatus() {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .get<Productivity.GetPublicationStatusResponse>(`${this.baseApiUrl}/productivity/magazine-publication/publication-status`
      , { headers });
  }

  public getAllPaperScope() {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .get<Productivity.GetPaperScopeResponse>(`${this.baseApiUrl}/productivity/paper/all-paper-scope`
      , { headers });
  }

  public getAllAuthorshipType() {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .get<Productivity.GetAuthorshipTypeResponse>(`${this.baseApiUrl}/productivity/book/all-authorship-type`
      , { headers });
  }

  public getAllCreationTypes() {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .get<Productivity.GetCreationTypesResponse>(`${this.baseApiUrl}/productivity/new-creations/all-creation-types`
      , { headers });
  }

  public createNewPublication(request: Productivity.CreatePublicationRequest) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<any>(`${this.baseApiUrl}/productivity/magazine-publication/new`, 
      request, { headers });
  }

  public updatePublication(request: any) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<any>(`${this.baseApiUrl}/productivity/magazine-publication/update`, 
      request, { headers });
  }

  public updateCreation(request: any) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<any>(`${this.baseApiUrl}/productivity/new-creations/update`, 
      request, { headers });
  }

  public updatePresentation(request: any) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<any>(`${this.baseApiUrl}/productivity/paper/update`, 
      request, { headers });
  }

  public updateThesis(request: any) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<any>(`${this.baseApiUrl}/productivity/thesis/update`, 
      request, { headers });
  }

  public updateBook(request: any) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<any>(`${this.baseApiUrl}/productivity/book/update`, 
      request, { headers });
  }

  public createNewBook(request: Productivity.CreateBookRequest) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<any>(`${this.baseApiUrl}/productivity/book/new`, 
      request, { headers });
  }

  public createNewPresentation(request: Productivity.CreatePresentationRequest) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<any>(`${this.baseApiUrl}/productivity/paper/new`, 
      request, { headers });
  }

  public createNewThesis(request: Productivity.CreateThesisRequest) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<any>(`${this.baseApiUrl}/productivity/thesis/new`, 
      request, { headers });
  }

  public createNewCreations(request: Productivity.NewCreationRequest) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<any>(`${this.baseApiUrl}/productivity/new-creations/new`, 
      request, { headers });
  }

  public getAllBooks(page: string, size: string) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .get<Productivity.GetBooksResponse>(`${this.baseApiUrl}/productivity/book/all-preview?page=${page}&size=${size}`
      , { headers });
  }

  public getAllPublications(page: string, size: string) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .get<Productivity.GetPublicationsResponse>(`${this.baseApiUrl}/productivity/magazine-publication/all-preview?page=${page}&size=${size}`
      , { headers });
  }

  public getAllPresentations(page: string, size: string) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .get<Productivity.GetPresentationsResponse>(`${this.baseApiUrl}/productivity/paper/all-preview?page=${page}&size=${size}`
      , { headers });
  }

  public getAllCreations(page: string, size: string) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .get<Productivity.GetCreationResponse>(`${this.baseApiUrl}/productivity/new-creations/all-preview?page=${page}&size=${size}`
      , { headers });
  }

  public getAllThesis(page: string, size: string) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .get<Productivity.GetThesisResponse>(`${this.baseApiUrl}/productivity/thesis/all-preview?page=${page}&size=${size}`
      , { headers });
  }

  public getAllMagazineCategories() {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .get<Productivity.GetMagazineCategoriesResponse>(`${this.baseApiUrl}/productivity/magazine/magazine-categories`
      , { headers });
  }

  public getAllThesisRole() {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .get<Productivity.GetThesisRoleResponse>(`${this.baseApiUrl}/productivity/thesis/all-thesis-role`
      , { headers });
  }

  public getAllThesisDegree() {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .get<Productivity.GetThesisDegreeResponse>(`${this.baseApiUrl}/productivity/thesis/all-thesis-degree`
      , { headers });
  }

  public approveNewCreationPoints(id: number) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<any>(`${this.baseApiUrl}/productivity/new-creations/approve-points?id=${id}`
      , null, { headers });
  }

  public approveBookPoints(id: number) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<any>(`${this.baseApiUrl}/productivity/book/approve-points?id=${id}`
      , null, { headers });
  }

  public approveThesisPoints(id: number) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<any>(`${this.baseApiUrl}/productivity/thesis/approve-points?id=${id}`
      , null, { headers });
  }

  public filterPublications(request: any, page: string, size: string) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<Productivity.GetPublicationsResponse>(`${this.baseApiUrl}/productivity/magazine-publication/all-filtered-preview?page=${page}&size=${size}`
      , request, { headers });
  }

  public filterBooks(request: any, page: string, size: string) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<Productivity.GetBooksResponse>(`${this.baseApiUrl}/productivity/book/all-filtered-preview?page=${page}&size=${size}`
      , request, { headers });
  }

  public filterPresentations(request: any, page: string, size: string) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<Productivity.GetPresentationsResponse>(`${this.baseApiUrl}/productivity/paper/all-filtered-preview?page=${page}&size=${size}`
      , request, { headers });
  }

  public filterThesis(request: any, page: string, size: string) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<Productivity.GetThesisResponse>(`${this.baseApiUrl}/productivity/thesis/all-filtered-preview?page=${page}&size=${size}`
      , request, { headers });
  }

  public filterCreations(request: any, page: string, size: string) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<Productivity.GetCreationResponse>(`${this.baseApiUrl}/productivity/new-creations/all-filtered-preview?page=${page}&size=${size}`
      , request, { headers });
  }

  public getAlertsPendingCount() {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .get<GetAlertsPendingCountResponse>(`${this.baseApiUrl}/productivity/alerts/pending-count`
      , { headers });
  }

}
