import { Injectable } from '@angular/core';
import { Observable, tap, of, map, catchError } from 'rxjs';
import {
  UserAccessResponse,
  UserInfoResponse,
  UserInformation,
  UserLoginData,
  UserLoginResponse,
  UserRegistrationData,
  UserRegistrationResponse,
} from '../interfaces/auth.interfaces';
import { HttpClient } from '@angular/common/http';
import { GlobalAppConfig } from '../../config/GlobalAppConfig';
import { OrganizationChartResponse } from '../../shared/interfaces/organization-chart.interfaces';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class MainAuthService {

  private baseApiUrl = GlobalAppConfig.getBaseApiContext();

  private headersWithoutToken = GlobalAppConfig.getHeadersWithoutToken();

  private authApiUrl = GlobalAppConfig.getAuthApiContext();

  private _userInformation: UserInformation = {
    id: null,
    username: null,
    firstName: null,
    secondName: null,
    firstLastName: null,
    secondLastName: null,
    email: null,
    roles: [],
    areaGroup: null,
    group: null,
    subDirection: null,
  };

  constructor(private http: HttpClient,
     private router: Router,) {}


  get UserInformation() {
    return { ...this._userInformation };
  }
  
  public updateUserInformation(userData: any) {
    this._userInformation = {
      ...this._userInformation,
      firstName: userData.firstName,
      secondName: userData.secondName,
      firstLastName: userData.firstLastName,
      secondLastName: userData.secondLastName,
      areaGroup: userData?.areaGroup?.id,
      group: userData?.group?.id,
      subDirection: userData?.subDirection?.id,
    }
  }

  public executeRegister( userData: UserRegistrationData ): Observable<UserRegistrationResponse> {
    return this.http
      .post<UserRegistrationResponse>(`${this.authApiUrl}/register`, 
        userData, 
        { headers: this.headersWithoutToken }
      )
  }

  public executeLogin(userData: UserLoginData): Observable<UserLoginResponse> {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    return this.http
      .post<UserLoginResponse>(
        `${this.authApiUrl}/login`, userData, { headers: this.headersWithoutToken })
      .pipe(
        tap((response) => {          
          if (response.ok) {
            localStorage.setItem('token', response.token);
            localStorage.setItem('username', response.username);
            this.checkRememberUserState(userData.rememberMe, userData.username);
          }
        })
      );
  }

    /**
   * check the remember user state
   * If remember me is checked, then set a new local storage item
   * with the name: rememberUser
   * The next time the user enters the login window, 
   * their username will be loaded into the username form input
   * @private
   * @return {void}
   * @memberOf MainAuthService
   */
  private checkRememberUserState(rememberUser: boolean, username: string){
    if(rememberUser){
      localStorage.setItem('rememberUser', username);
    }

    if(!rememberUser){
      localStorage.removeItem('rememberUser');
    }

  }

  public executeValidateUserAccess(email: string): Observable<UserAccessResponse> {
    return this.http
      .get<UserAccessResponse>(
        `${this.authApiUrl}/access?email=${email}`, 
        { headers: this.headersWithoutToken }
      )
  }

  public loadUserInfo() {
    const headers = GlobalAppConfig.getHeadersWithToken();
    if(!headers){
      return;
    }    
    const username = localStorage.getItem('username');
    return this.http
    .get<UserInfoResponse>(`${this.baseApiUrl}/user-query/user-info?username=${username}`, 
    { headers })
    .pipe(
      map((response: UserInfoResponse) => {   
        this._userInformation = response.message;
          return true;
        }),
        catchError((err) => of(false))
      );
  }

  public logout(){
    this._userInformation=null;
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    this.router.navigate(['/auth/login']);
  }

}
