import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { ROUTES } from './sidebar-items';
import { MainAuthService } from '../../auth/services/main-auth.service';
import { ProjectService } from 'src/app/dashboard/services/project.service';
import { MatDialog } from '@angular/material/dialog';
import { DownloadProductivityReportDialogComponent } from 'src/app/dashboard/dialogs/components/productivity/download-productivity-report-dialog/download-productivity-report-dialog.component';
import { ProductivityService } from 'src/app/dashboard/services/productivity.service';
import { GetAlertsPendingCountData, GetAlertsPendingCountResponse } from 'src/app/dashboard/interfaces/productivity.interface';
import { Logger } from 'src/app/utils/logger-util';
import { Alert } from 'src/app/utils/alert-util';


/**
   * rolMap
   * @type {Map<string, string>}
   * @memberOf SidebarComponent
   */
 let rolMap = new Map<string, string>();

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  /**
   * The logger
   * @private
   * @type {Logger}
   * @memberOf SidebarComponent
   */
  private logger: Logger = new Logger(SidebarComponent);
  
  public sidebarItems: any[];
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  userFullName: string;
  userImg: string;
  userType: string;
  headerHeight = 60;
  currentRoute: string;
  routerObj = null;

  alertsPendingCount: GetAlertsPendingCountData = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: MainAuthService,
    private router: Router,
    private mainAuthService: MainAuthService,
    private projectService: ProjectService,
    private productivityService: ProductivityService,
    public dialog: MatDialog,
  ) {
    const body = this.elementRef.nativeElement.closest('body');
    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, 'overlay-open');
      }
    });
  }
  @HostListener('window:resize', ['$event'])
  windowResizecall(event) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, 'overlay-open');
    }
  }
  callToggleMenu(event: any, length: any) {
    
    if (length > 0) {
      const parentElement = event.target.closest('li');
      const activeClass = parentElement.classList.contains('active');

      if (activeClass) {
        this.renderer.removeClass(parentElement, 'active');
      } else {
        this.renderer.addClass(parentElement, 'active');
      }
    }
  }

  protected currentUserRol: string = '';

  ngOnInit() {
    this.loadAlertsTendingCount();
    this.initRolMap();
    this.initSidebarInfo();
    this.initLeftSidebar();
    this.bodyTag = this.document.body;
  }
  
  ngOnDestroy() {
    this.routerObj.unsubscribe();
  }

  private loadAlertsTendingCount() {
    this.productivityService.getAlertsPendingCount()
    .subscribe({
      next: (response: GetAlertsPendingCountResponse) => {
        this.logger.debug('Success response getting the alerts pending count: ', response);
        if (response.ok) {
          this.alertsPendingCount = response.message;
        }
      },
      error: (response: any) => {
        this.logger.error('Error response getting the alerts pending count: ', response);
        // Alert.info('Error', 'Hubo un error al cargar la lista de publicaciones. Trate de nuevo en un momento, si el problema persiste, por favor contacte al administrador.');
      }
    });
  }

  private initSidebarInfo(){
    this.currentUserRol = this.mainAuthService.UserInformation?.roles[0]?.role;
    this.userFullName = `${this.mainAuthService.UserInformation.firstName} ${this.mainAuthService.UserInformation.firstLastName}`;
    this.userImg = `assets/images/user-resources/${this.currentUserRol}.png`;
    this.sidebarItems = ROUTES.filter(
      (x) => x.role.some((rol: string) => rol === this.currentUserRol) || x.role.some((rol: string) => rol === 'ALL')
    );

    this.userType = rolMap.get(this.currentUserRol);
  }

  initLeftSidebar() {
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }
  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + '';
    this.listMaxWidth = '500px';
  }
  isOpen() {
    return this.bodyTag.classList.contains('overlay-open');
  }
  checkStatuForResize(firstTime) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, 'ls-closed');
    } else {
      this.renderer.removeClass(this.document.body, 'ls-closed');
    }
  }
  mouseHover(e) {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('submenu-closed')) {
      this.renderer.addClass(this.document.body, 'side-closed-hover');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    }
  }
  mouseOut(e) {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('side-closed-hover')) {
      this.renderer.removeClass(this.document.body, 'side-closed-hover');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }

  protected downloadReport(){
    this.projectService.downloadExcelFile('Proyectos', 'researchProject/excel-report');    
  }

  protected openDownloadReportDialog(){
    const dialogRef = this.dialog.open(DownloadProductivityReportDialogComponent, {
      data: {
        title: `Descargar reporte`,
      },
    });

    dialogRef.afterClosed()
    .subscribe((data: any) => {
      if(data){
        this.downloadProductivityReport(data?.productivityName);
      }
    });
  }

  private downloadProductivityReport(productivityName: string){
    let fileName = '';
    let url = '';
    switch (productivityName) {
      case 'Publicación':
        fileName = 'Publicación';
        url='productivity/magazine-publication/excel-report';
        break;
      case 'Nueva Creación':
        fileName = 'Nueva_creación';
        url='productivity/new-creations/excel-report';
        break;
      case 'Ponencia':
        fileName = 'Ponencia';
        url='productivity/paper/excel-report';
        break;
      case 'Tesis':
        fileName = 'Tesise';
        url='productivity/thesis/excel-report';
        break;
      case 'Libro':
        fileName= 'Libro';
        url='productivity/book/excel-report';
        break;
    }
    
    if(fileName && url){
      this.projectService.downloadExcelFile(fileName, url);
    }
  }
  
  logout() {
    this.authService.logout();
  }

  private initRolMap(){
    rolMap.set('ROLE_SUPER_ADMIN', 'SUPER ADMINISTRADOR');

    rolMap.set('ROLE_ADMIN', 'ADMINISTRADOR');
    rolMap.set('ROLE_DIRECTOR', 'DIRECTOR');
    rolMap.set('ROLE_SUBDIRECTOR', 'SUBDIRECTOR');
    rolMap.set('ROLE_COORDINADOR_GRUPO_AREA', 'COORDINADOR GRUPO AREA');
    rolMap.set('ROLE_COORDINADOR_GRUPO', 'COORDINADOR GRUPO');
    rolMap.set('ROLE_COORDINADOR_DE_ESTUDIO', 'COORDINADOR DE ESTUDIO');
    rolMap.set('ROLE_MONITOR', 'MONITOR');
    rolMap.set('ROLE_INVESTIGADOR', 'INVESTIGADOR');
  }
}
