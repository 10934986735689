<nav #navbar class="navbar active">
  <div class="container-fluid">
    <div class="navbar-header">
      <a *ngIf="showLogo" href="#" onClick="return false;" class="bars"
        (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a *ngIf="showLogo" class="bars" style="cursor: pointer;"
        (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>

      <a *ngIf="showLogo" style="width: 90%;" class="navbar-brand" routerLink={{homePage}}>
        <img src="assets/images/logo/logo_inc_2.png" width="120" alt="" />
      </a>
      <button *ngIf="!showLogo" mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
        <i class="material-icons-outlined icon-color">
          menu
        </i>
      </button>
      <!-- <a *ngIf="showLogo" style="width: 90%;" class="navbar-brand" routerLink={{homePage}}>
        <img src="assets/images/logo/logo_short.png" width="35" alt="" />
      </a> -->
      <div class="navbar-toggle" style="margin-top: -100px;">
        <div>
          <span class="current-page-text">
            {{currentPageText}}
          </span>
        </div>
      </div>
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="float-start collapse-menu-icon">
        <li>
          <!-- <button mat-icon-button (click)="callSidemenuCollapse($event,'overlay-open')" class="sidemenu-collapse"> -->
          <button *ngIf="showLogo" mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <i class="material-icons-outlined icon-color">
              menu
            </i>
          </button>
        </li>
      </ul>

      <ul class="nav navbar-nav navbar-right">
        <li>
          <span class="current-page-text-2">{{currentPageText}}</span>
        </li>
      </ul>

    </div>
  </div>
</nav>