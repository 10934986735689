import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MainAuthService } from '../../auth/services/main-auth.service';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(private authenticationService: MainAuthService,
    private _snackBar: MatSnackBar
    ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {        
        if ((err.status === 401 || err.status === 403) && localStorage.getItem('token')) {
          // auto logout if 401 response returned from api
          this.openSnackBar();
          this.authenticationService.logout();
          // location.reload();
        }

        // const error = err?.error?.message || err?.statusText;
        return throwError(err);
      })
    );
  }

  private openSnackBar() {
    this._snackBar.open('Su sesión ha expirado', 'Cerrar', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}
