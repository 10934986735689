import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { Observable, tap } from 'rxjs';
import { MainAuthService } from '../services/main-auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthTokenGuard implements CanActivate, CanLoad {
  constructor(private authService: MainAuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    console.log('canActivate');

    return this.authService.loadUserInfo().pipe(
      tap((valid) => {
        if (!valid) {
          // Redirect to Login
          this.router.navigate(['/auth/login']);
        }
      })
    );
  }
  
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | boolean {
    console.log('canLoad');

    return this.authService.loadUserInfo().pipe(
      tap((valid) => {
        if (!valid) {
          // Redirect to Login
          this.router.navigate(['/auth/login']);
        }
      })
    );
  }
}
