import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalAppConfig } from '../../config/GlobalAppConfig';
import { BasicResponse, CommonDropdownsResponse, CreateProject, UserDropdownsResponse, DropDown, GetProjectsResponse, AllUsersResponse, FilterProjectData, GetProjectResponse, ProjectDetailData, GetProjectPointsResponse } from '../interfaces/project.interface';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private baseApiUrl = GlobalAppConfig.getBaseApiContext();

  private _projectData: ProjectDetailData = null;

  constructor(private http: HttpClient) { }

  get ProjectInformation() {
    return { ...this._projectData };
  }

  public updateProjectInformation(projectData: ProjectDetailData) {
    this._projectData = projectData;
  }

  public getUsersDropdown() {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .get<UserDropdownsResponse>(`${this.baseApiUrl}/drop-down-menu/users-for-dropdowns`
      , { headers });
  }

  public getCommonDropdown() {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .get<CommonDropdownsResponse>(`${this.baseApiUrl}/drop-down-menu`
      , { headers });
  }

  public exixsProjectByCode(sapCode: string) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .get<BasicResponse>(`${this.baseApiUrl}/researchProject/queries/exists-by-code?code=${sapCode}`
      , { headers });
  }

  public createNewProject(request: CreateProject.Request) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<any>(`${this.baseApiUrl}/researchProject/create-new-project`, 
      request, { headers });
  }

  public updateProject(request: any) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<any>(`${this.baseApiUrl}/researchProject/update-project`, 
      request, { headers });
  }

  public getDegreeLevelList() {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .get<DropDown.DegreeLevelResponse>(`${this.baseApiUrl}/drop-down-menu/degree-level`
      , { headers });
  }

  public getPartnershipInstitutionsList() {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .get<DropDown.PartnershipInstitutionsResponse>(`${this.baseApiUrl}/drop-down-menu/partnership-institutions`
      , { headers });
  }

  public getAllProjects(page: string, size: string) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .get<GetProjectsResponse>(`${this.baseApiUrl}/researchProject/queries/all-preview?page=${page}&size=${size}`
      , { headers });
  }

  public getFilterProjects(request: FilterProjectData, page: string, size: string) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<GetProjectsResponse>(`${this.baseApiUrl}/researchProject/queries/find-by-query?page=${page}&size=${size}`
      , request, { headers });
  }

  public getAllUsers() {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .get<AllUsersResponse>(`${this.baseApiUrl}/drop-down-menu/all-user-names`
      , { headers });
  }

  public getProjectById(id: number) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .get<GetProjectResponse>(`${this.baseApiUrl}/researchProject/queries/find-by-id?id=${id}`
      , { headers });
  }

  public getPointsById(id: number) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .get<GetProjectPointsResponse>(`${this.baseApiUrl}/researchProject/queries/points-by-id?id=${id}`
      , { headers });
  }

  public newDiscrepancy(request: any) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<any>(`${this.baseApiUrl}/researchProject/new-discrepancy`, 
      request, { headers });
  }

  public newAmendment(request: any) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<any>(`${this.baseApiUrl}/researchProject/new-amendment`, 
      request, { headers });
  }

  public newMayorDesviation(request: any) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<any>(`${this.baseApiUrl}/researchProject/new-mayor-deviation`, 
      request, { headers });
  }

  public newMinorDesviation(request: any) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<any>(`${this.baseApiUrl}/researchProject/new-minor-deviation`, 
      request, { headers });
  }

  public newWindowExit(request: any) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<any>(`${this.baseApiUrl}/researchProject/new-window-exit`, 
      request, { headers });
  }

  public newClosingDate(request: any) {
    const headers = GlobalAppConfig.getHeadersWithToken();
    return this.http
      .post<any>(`${this.baseApiUrl}/researchProject/new-closing-date`, 
      request, { headers });
  }

  public downloadExcelFile(fileNamePrefix: string, url: string): void {
    const headers = GlobalAppConfig.getHeadersWithToken();
    this.http.get(`${this.baseApiUrl}/${url}`, { responseType: 'blob', headers })
      .subscribe(data => {
        saveAs(data, `${fileNamePrefix}_${this.getCurrentDate()}.xlsx`);
      }, error => {
        console.error(error);
      });
  }

  private getCurrentDate(): string {
    const date = new Date();
    return `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
  }

}
