import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-download-productivity-report-dialog',
  templateUrl: './download-productivity-report-dialog.component.html',
  styleUrls: ['./download-productivity-report-dialog.component.scss']
})
export class DownloadProductivityReportDialogComponent implements OnInit {

  /**
   * The common reportFormRef Form Ref
   * @protected
   * @type {FormGroup}
   * @memberOf DownloadProductivityReportDialogComponent
   */
  protected reportFormRef: FormGroup = this.formBuilder.group(
    {
      productivityName: ['', [ Validators.required, ] ],
    },

  );

  productivityNameList : string[] = ['Publicación', 'Nueva Creación', 'Ponencia', 'Tesis', 'Libro'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DownloadProductivityReportDialogComponent>,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
  }

  protected select(){
    if(this.reportFormRef.invalid){
      this.reportFormRef.markAllAsTouched();
      return;
    }

    this.dialogRef.close(this.reportFormRef.value);
  }

  /**
   * productivityNamesage
   * Validate if the selectedName is valid
   * @return {string}
   * @memberOf DownloadProductivityReportDialogComponent
   */
  get productivityNameErrorMessage(): string {
    const productivityNameErrors = this.reportFormRef.get('productivityName')?.errors;

    if (productivityNameErrors?.['required']) {
      return 'Debe seleccionar una opción.';
    }

    return '';
  }

}
