<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <form [formGroup]="reportFormRef">
    <div class="input-size form-content mb-2">

      <mat-form-field>
        <mat-select placeholder="Seleccionar" formControlName="productivityName">
          <mat-option *ngFor="let productivity of productivityNameList" [value]="productivity">
            {{ productivity }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{ productivityNameErrorMessage }}
        </mat-error>
      </mat-form-field>

    </div>

    <div class="custom mb-2">
      <button (click)="select()" class="button-custom-style" mat-raised-button color="success">
        DESCARGAR
      </button>
      <button (click)="dialogRef.close()" class="button-custom-style" style="margin-left: 10px;" mat-raised-button
        color="warn">
        CANCELAR
      </button>
    </div>
  </form>
</div>