import { environment } from '../../environments/environment';
import { HttpHeaders } from '@angular/common/http';


/**
 * GlobalAppConfig
 * @export
 * @class GlobalAppConfig
 */
export class GlobalAppConfig {

  /**
   * Gets the authorization api context
   * @returns {string}
   * @memberOf GlobalAppConfig
   */
  static getAuthApiContext(): string{
    return environment.authApiUrl;
  }

  /**
   * Gets the base api context
   * @returns {string}
   * @memberOf GlobalAppConfig
   */
  static getBaseApiContext(): string{
    return environment.baseApiUrl;
  }

  /**
   * Gets the request headers with token
   * @static
   * @returns {HttpHeaders}
   * @memberOf GlobalAppConfig
   */
  static getHeadersWithToken(): HttpHeaders{
    if(!localStorage.getItem('token')){
      return null;
    }
    return new HttpHeaders()
      .set('Authorization', `Bearer ${localStorage.getItem('token')}`)
      .set('Content-Type', 'application/json')
  }

  /**
   * Gets the request headers with token to excel file
   * @static
   * @returns {HttpHeaders}
   * @memberOf GlobalAppConfig
   */
  static getHeadersWithTokenToExcel(): HttpHeaders | null{
    if(!localStorage.getItem('token')){
      return null;
    }
    return new HttpHeaders()
      .set('Authorization', `Bearer ${localStorage.getItem('token')}`)
      .set('Accept', 'application/json')
      .delete('Content-Type');
  }

  /**
   * Gets the request headers without token
   * @static
   * @returns {HttpHeaders}
   * @memberOf GlobalAppConfig
   */
  static getHeadersWithoutToken(): HttpHeaders{
    return new HttpHeaders().set('content-type', 'application/json');
  }
  
  /**
   * Gets the pass regular expression
   * @static
   * @returns {RegExp}
   * @memberOf GlobalAppConfig
   */
  static getPassRgex(): RegExp {
    return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{10,15}$/;
  }


}
