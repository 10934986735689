import { RouteInfo } from './sidebar.metadata';
export const ROUTES: RouteInfo[] = [
  {
    path: 'dashboard',
    title: 'Inicio',
    iconType: 'material-icons-two-tone',
    icon: 'grid_view',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ALL'],
    submenu: [],
  },
  {
    path: '',
    title: 'Ver productividad',
    iconType: 'material-icons-two-tone',
    icon: 'eject',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ALL'],
    submenu: [
      // receipt
      {
        path: 'dashboard/productivity/publications-list',
        title: 'Publicación',
        iconType: 'material-icons-two-tone',
        // icon: 'chrome_reader_mode',
        icon: 'burst_mode',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ALL'],
        submenu: [],
      },
      {
        path: 'dashboard/productivity/creations-list',
        title: 'Nueva creación',
        iconType: 'material-icons-two-tone',
        icon: 'source',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ALL'],
        submenu: [],
      },
      {
        path: 'dashboard/productivity/presentations-list',
        title: 'Ponencia',
        iconType: 'material-icons-two-tone',
        // icon: 'burst_mode',
        icon: 'dvr',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ALL'],
        submenu: [],
      },
      {
        path: 'dashboard/productivity/thesis-list',
        title: 'Tesis',
        iconType: 'material-icons-two-tone',
        // icon: 'dvr',
        icon: 'mode_edit',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ALL'],
        submenu: [],
      },
      {
        path: 'dashboard/productivity/books-list',
        title: 'Libro',
        iconType: 'material-icons-two-tone',
        icon: 'chrome_reader_mode',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ALL'],
        submenu: [],
      },
    ],
  },
  {
    // A TODOS EXCEPTO AL INVESTIGADOR
    path: '',
    title: 'Proyectos',
    iconType: 'material-icons-two-tone',
    icon: 'business',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_SUPER_ADMIN', 'ROLE_DIRECTOR', 'ROLE_SUBDIRECTOR', 'ROLE_ADMIN', 'ROLE_COORDINADOR_GRUPO_AREA',
      'ROLE_COORDINADOR_GRUPO', 'ROLE_COORDINADOR_DE_ESTUDIO', 'ROLE_MONITOR'],
    submenu: [
      // receipt
      {
        path: 'dashboard/projects/create-project',
        title: 'Crear proyecto',
        iconType: 'material-icons-two-tone',
        icon: 'toc',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_SUPER_ADMIN', 'ROLE_DIRECTOR', 'ROLE_SUBDIRECTOR', 'ROLE_ADMIN', 'ROLE_COORDINADOR_GRUPO_AREA',
      'ROLE_COORDINADOR_GRUPO', 'ROLE_COORDINADOR_DE_ESTUDIO', 'ROLE_MONITOR'],
        submenu: [],
      },
      {
        path: 'dashboard/projects/magazines',
        title: 'Revistas',
        iconType: 'material-icons-two-tone',
        icon: 'menu_book',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_SUPER_ADMIN', 'ROLE_DIRECTOR', 'ROLE_SUBDIRECTOR', 'ROLE_ADMIN', 'ROLE_COORDINADOR_GRUPO_AREA',
      'ROLE_COORDINADOR_GRUPO', 'ROLE_COORDINADOR_DE_ESTUDIO', 'ROLE_MONITOR'],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Usuarios',
    iconType: 'material-icons-two-tone',
    icon: 'supervisor_account',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_SUPER_ADMIN'],
    submenu: [
      {
        path: 'dashboard/users/pre-registration',
        title: 'PreRegistros',
        iconType: 'material-icons-two-tone',
        icon: 'add',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_SUPER_ADMIN'],
        submenu: [],
      },
      {
        path: 'dashboard/users/manage-users',
        title: 'Gestionar usuarios',
        iconType: 'material-icons-two-tone',
        icon: 'perm_identity',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_SUPER_ADMIN'],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Configuración',
    iconType: 'material-icons-two-tone',
    icon: 'settings',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_SUPER_ADMIN'],
    submenu: [
      {
        path: 'dashboard/configuration/manage-lists',
        title: 'Gestionar Listas',
        iconType: 'material-icons-two-tone',
        icon: 'storage',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_SUPER_ADMIN'],
        submenu: [],
      },
      {
        path: 'dashboard/configuration/manage-groups',
        title: 'Gestionar grupos',
        iconType: 'material-icons-two-tone',
        icon: 'group_work',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_SUPER_ADMIN'],
        submenu: [],
      },
    ],
  },
  {
    path: 'dashboard/profile',
    title: 'Perfil',
    iconType: 'material-icons-two-tone',
    icon: 'face',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ALL'],
    submenu: [],
  },
];
