/**
 * Logger
 * @export
 * @class Logger
 */
export class Logger {

  /**
   * Object origin name
   * @private
   * @type {string}
   * @memberOf Logger
   */
  private objOriginName: string;

  /**
   * Creates an instance of Logger.
   * E.g example to use: private logger: Logger = new Logger(HomeComponent);
   * @param {*} classPrototype class object
   * @memberOf Logger
   */
  // tslint:disable-next-line: no-any
  constructor(classPrototype: any) {
    this.objOriginName = this.getObjName(classPrototype);
    // Logger.success('this.objOriginName : ', this.objOriginName);
  }

  /**
   * colorLog
   * @private
   * @static
   * @param {any} message
   * @param {any} color
   * @memberOf Logger
   */
  private static colorLog(level: string) {
    let color = 'Gray';
    switch (level) {
      case 'success':
        color = 'Green';
        break;
      case 'info':
        color = 'DodgerBlue';
        break;
      case 'error':
        color = 'Red';
        break;
      case 'warn':
        color = 'Orange';
        break;
      default:
        color = color;
    }
    return 'color:' + color;
  }

  /**
   * defaultPrefix
   * @private
   * @static
   * @param {string} level
   * @returns {string}
   * @memberOf Logger
   */
  private static defaultPrefix(level: string, originName?: string): string {
    if (originName) {
      return '%c [' + level + '][' + originName + '] -> ';
    }
    return '%c [' + level + '] -> ';
  }

  /**
   * success
   * @static
   * @param {string} message
   * @param {...any[]} optionalParams
   * @memberOf Logger
   */
  // tslint:disable-next-line: no-any
  static success(message: string, ...optionalParams: any[]) {
    console.log(this.defaultPrefix('success') + message, this.colorLog('success'), ...optionalParams);
  }

  /**
   * info
   * @static
   * @param {string} message
   * @param {...any[]} optionalParams
   * @memberOf Logger
   */
  // tslint:disable-next-line: no-any
  static info(message: string, ...optionalParams: any[]) {
    console.log(this.defaultPrefix('info') + message, this.colorLog('info'), ...optionalParams);
  }

  /**
   * debug
   * @static
   * @param {string} message
   * @param {...any[]} optionalParams
   * @memberOf Logger
   */
  // tslint:disable-next-line: no-any
  static debug(message: string, ...optionalParams: any[]) {
    console.log(this.defaultPrefix('debug') + message, this.colorLog('debug'), ...optionalParams);
  }

  /**
   * error
   * @static
   * @param {string} message
   * @param {...any[]} optionalParams
   * @memberOf Logger
   */
  // tslint:disable-next-line: no-any
  static error(message: string, ...optionalParams: any[]) {
    console.log(this.defaultPrefix('error') + message, this.colorLog('error'), ...optionalParams);
  }

  /**
   * warn
   * @static
   * @param {string} message
   * @param {...any[]} optionalParams
   * @memberOf Logger
   */
  // tslint:disable-next-line: no-any
  static warn(message: string, ...optionalParams: any[]) {
    console.log(this.defaultPrefix('warn') + message, this.colorLog('warn'), ...optionalParams);
  }

  /**
   * Gets the object name
   * @private
   * @param {*} obj
   * @returns {string}
   * @memberOf Logger
   */
  // tslint:disable-next-line: no-any
  private getObjName(obj: any): string {
    if (obj.name) {
      return obj.name;
    }
    let funcNameRegex = /function (.{1,})\(/;
    let results = (funcNameRegex).exec(obj.toString());
    let result = results && results.length > 1 && results[1];
    if (!result) {
      funcNameRegex = /return .([^;]+)/;
      results = (funcNameRegex).exec(obj.toString());
      result = results && results.length > 1 && results[1].split('.').pop();
    }
    return result || '';
  }

  // tslint:disable-next-line: no-any
  success(message: string, ...optionalParams: any[]) {
    console.log(Logger.defaultPrefix('success', this.objOriginName) + message,
      Logger.colorLog('success'), ...optionalParams);
  }

  // tslint:disable-next-line: no-any
  info(message: string, ...optionalParams: any[]) {
    console.log(Logger.defaultPrefix('info', this.objOriginName) + message,
      Logger.colorLog('info'), ...optionalParams);
  }

  // tslint:disable-next-line: no-any
  debug(message: string, ...optionalParams: any[]) {
    console.log(Logger.defaultPrefix('debug', this.objOriginName) + message,
      Logger.colorLog('debug'), ...optionalParams);
  }

  // tslint:disable-next-line: no-any
  error(message: string, ...optionalParams: any[]) {
    console.log(Logger.defaultPrefix('error', this.objOriginName) + message,
      Logger.colorLog('error'), ...optionalParams);
  }

  // tslint:disable-next-line: no-any
  warn(message: string, ...optionalParams: any[]) {
    console.log(Logger.defaultPrefix('warn', this.objOriginName) + message,
      Logger.colorLog('warn'), ...optionalParams);
  }
}
