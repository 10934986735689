import { ConfigService } from '../../config/config.service';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  AfterViewInit,
} from '@angular/core';
import { AuthService } from 'src/app/core/service/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { RightSidebarService } from 'src/app/core/service/rightsidebar.service';
import { LanguageService } from 'src/app/core/service/language.service';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/UnsubscribeOnDestroyAdapter';
const document: any = window.document;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends UnsubscribeOnDestroyAdapter implements OnInit, AfterViewInit {
  public config: any = {};
  isNavbarCollapsed = true;
  isOpenSidebar: boolean;
  
  showLogo = true;
  homePage = 'dashboard';
  currentPageText = '';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private rightSidebarService: RightSidebarService,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    public languageService: LanguageService,
  ) {
    super();
    this.addOnChangeRoute();
  }

  ngOnInit() {
    this.config = this.configService.configData;    
  }

  ngAfterViewInit() {

    // CODE SECTION GETTED FROM RIGHR SIDEBAR COMPONENT
    if (localStorage.getItem('choose_skin')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('choose_skin')
      );
      localStorage.getItem('choose_skin_active');
    } else {
      this.renderer.addClass(
        this.document.body,
        'theme-' + this.config.layout.theme_color
      );
      this.config.layout.theme_color;
    }


    // set theme on startup
    if (localStorage.getItem('theme')) {
      this.renderer.removeClass(this.document.body, this.config.layout.variant);
      this.renderer.addClass(this.document.body, localStorage.getItem('theme'));
    } else {
      this.renderer.addClass(this.document.body, this.config.layout.variant);
    }

    if (localStorage.getItem('menuOption')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('menuOption')
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        'menu_' + this.config.layout.sidebar.backgroundColor
      );
    }

    if (localStorage.getItem('choose_logoheader')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('choose_logoheader')
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        'logo-' + this.config.layout.logo_bg_color
      );
    }

    if (localStorage.getItem('sidebar_status')) {
      if (localStorage.getItem('sidebar_status') === 'close') {
        this.showLogo = true;
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      } else {
        this.showLogo = false;
        this.renderer.removeClass(this.document.body, 'side-closed');
        this.renderer.removeClass(this.document.body, 'submenu-closed');
      }
    } else {
      
      if (this.config.layout.sidebar.collapsed === true) {
        this.showLogo = true;
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      }
    }
  }
  callFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  mobileMenuSidebarOpen(event: any, className: string) {
    
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
      this.renderer.removeClass(this.document.body, 'ls-closed');
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }
  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains('side-closed');
    if (hasClass) {
      this.showLogo = true;
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
      this.renderer.removeClass(this.document.body, 'ls-closed');
    } else {
      this.showLogo = false;
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
      this.renderer.addClass(this.document.body, 'ls-closed');
    }
  }

  /**
   * addOnChangeRoute
   * @private
   * @memberOf HeaderComponent
   */
  private addOnChangeRoute() {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const urlArray = event.url.split('/');
        const lastUrlItem = urlArray.slice(-1);         
        if(lastUrlItem?.length){

          this.currentPageText = this.getCurrentPage(lastUrlItem[0].split('?')[0]);
        }
      }
    })
  }

  private getCurrentPage(currentPage: string): string {
    switch (currentPage) {
      case 'dashboard':
        return 'Inicio';
      case 'manage-users':
        return 'Gestionar usuarios';
      case 'pre-registration':
        return 'PreRegistros';
      case 'manage-groups':
        return 'Gestionar grupos';
      case 'manage-lists':
        return 'Gestionar listas';
      case 'profile':
        return 'Perfil';
      case 'publications-list':
        return 'Publicaciones';
      case 'creations-list':
        return 'Creaciones';
      case 'presentations-list':
        return 'Ponencias';
      case 'thesis-list':
        return 'Tesis';
      case 'books-list':
        return 'Libros';
      case 'create-project':
        return 'Crear proyecto';
      case 'magazines':
        return 'Revistas';
      case 'new-publication':
        return 'Nueva publicación';
      case 'new-presentation':
        return 'Nueva ponencia';
      case 'new-book':
        return 'Nuevo libro';
      case 'new-thesis':
        return 'Nueva tesis';
      case 'new-creation':
        return 'Nueva creación';
      default:
        return '';
    }
  }
}
