<div>
  <!-- Left Sidebar -->
  <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">

    <!-- [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}" -->
    <!-- <aside id="leftsidebar" class="sidebar"> -->
    <!-- Menu -->
    <div class="menu">
      <ul class="list"
        [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}"
        [perfectScrollbar]>
        <li class="sidebar-user-panel">
          <div class="user-panel">
            <div class=" image">
              <img src={{userImg}} class="img-circle user-img-circle" alt="User Image" />
            </div>
          </div>
          <div class="profile-usertitle mb-4">
            <div class="sidebar-userpic-name">{{userFullName}} </div>
            <span style="color: #484C72;">{{userType}}</span>
          </div>
        </li>
        <!-- Top Most level menu -->
        <li style="cursor: pointer;" *ngFor="let sidebarItem of sidebarItems"
          [routerLinkActive]="sidebarItem.submenu.length != 0 ? 'active' : ''">
          <div class="header" *ngIf="sidebarItem.groupTitle === true">{{sidebarItem.title | translate}}</div>
          <a style="padding: 0px 9px 0px 9px; margin: 0px 11px 0px 11px;"
            [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null" *ngIf="!sidebarItem.groupTitle;"
            [ngClass]="[sidebarItem.class]" (click)="callToggleMenu($event, sidebarItem.submenu.length)"
            class="menu-top">
            <i style="font-size: 17px;" [ngClass]="[sidebarItem.iconType]">{{sidebarItem.icon}}</i>
            <span style="font-weight: bold;" class="hide-menu text-item">{{sidebarItem.title | translate}}
            </span>
            <span class="text-item" *ngIf="sidebarItem.badge != '' "
              [ngClass]="[sidebarItem.badgeClass]">{{sidebarItem.badge}}</span>
          </a>
          <!-- First level menu -->


          <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
            <li *ngFor="let sidebarSubItem1 of sidebarItem.submenu"
              [routerLinkActive]="sidebarSubItem1.submenu.length > 0 ? '' : 'active'">
              <a class="submenu" [routerLink]="sidebarSubItem1.submenu.length > 0 ? null : [sidebarSubItem1.path]"
                (click)="callToggleMenu($event,sidebarSubItem1.submenu.length)" [ngClass]="[sidebarSubItem1.class]">
                <div class="badge-content" style="justify-content: space-between; display: flex;">

                  <span class="text-item">
                    <i style="font-size: 17px;" [ngClass]="[sidebarSubItem1.iconType]">
                      {{sidebarSubItem1.icon}}
                    </i> {{sidebarSubItem1.title | translate}}
                  </span>

                  <div *ngIf="currentUserRol==='ROLE_ADMIN'">
                    <div
                      *ngIf="sidebarSubItem1.title==='Nueva creación' && alertsPendingCount?.creationPendingApprovalPoints > 0"
                      matBadgeColor="warn" [matBadge]="alertsPendingCount?.creationPendingApprovalPoints"
                      matBadgeOverlap="false" class="demo-section"
                    ></div>

                    <div
                      *ngIf="sidebarSubItem1.title==='Publicación' && alertsPendingCount?.publicationPendingApprovalPoints > 0"
                      matBadgeColor="warn" [matBadge]="alertsPendingCount?.publicationPendingApprovalPoints"
                      matBadgeOverlap="false" class="demo-section"
                    ></div>

                    <div
                      *ngIf="sidebarSubItem1.title==='Ponencia' && alertsPendingCount?.paperPendingApprovalPoints > 0"
                      matBadgeColor="warn" [matBadge]="alertsPendingCount?.paperPendingApprovalPoints"
                      matBadgeOverlap="false" class="demo-section"
                    ></div>

                    <div *ngIf="sidebarSubItem1.title==='Tesis' && alertsPendingCount?.thesisPendingApprovalPoints > 0"
                      matBadgeColor="warn" [matBadge]="alertsPendingCount?.thesisPendingApprovalPoints"
                      matBadgeOverlap="false" class="demo-section"
                    ></div>

                    <div *ngIf="sidebarSubItem1.title==='Libro' && alertsPendingCount?.bookPendingApprovalPoints > 0"
                      matBadgeColor="warn" [matBadge]="alertsPendingCount?.bookPendingApprovalPoints"
                      matBadgeOverlap="false" class="demo-section"
                    ></div>

                    <div *ngIf="sidebarSubItem1.title==='Revistas' && alertsPendingCount?.magazinePendingApproval > 0"
                         matBadgeColor="warn" [matBadge]="alertsPendingCount?.magazinePendingApproval"
                         matBadgeOverlap="false" class="demo-section"
                    ></div>

                  </div>
                </div>
              </a>

              <!-- Second level menu -->
              <ul class="ml-menu-2" *ngIf="sidebarSubItem1.submenu.length > 0">
                <li *ngFor="let sidebarSubItem2 of sidebarSubItem1.submenu"
                  [routerLinkActive]="sidebarSubItem2.submenu.length > 0 ? '' : 'active'">
                  <a [routerLink]="sidebarSubItem2.submenu.length > 0 ? null : [sidebarSubItem2.path]"
                    (click)="callToggleMenu($event,sidebarSubItem2.submenu.length)" [ngClass]="[sidebarSubItem2.class]">
                    {{sidebarSubItem2.title | translate}}
                  </a>
                  <!-- Third level menu -->
                  <ul class="ml-menu-3" *ngIf="sidebarSubItem2.submenu.length > 0">
                    <li *ngFor="let sidebarSubItem3 of sidebarSubItem2.submenu"
                      [routerLinkActive]="sidebarSubItem3.submenu.length > 0 ? '' : 'active'">
                      <a [routerLink]="sidebarSubItem3.submenu.length > 0 ? null : [sidebarSubItem3.path]"
                        (click)="callToggleMenu($event,sidebarSubItem3.submenu.length)"
                        [ngClass]="[sidebarSubItem3.class]">
                        {{sidebarSubItem3.title | translate}}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

          </ul>

          <ul class="ml-menu" *ngIf="sidebarItem.title==='Ver productividad' && currentUserRol==='ROLE_ADMIN'">
            <li>
              <a class="submenu" (click)="openDownloadReportDialog()" ngClass="ml-menu">
                <i style="font-size: 17px;" ngClass="material-icons-two-tone">library_books</i>
                <span class="text-item">Reportes</span>
              </a>
            </li>
          </ul>

          <ul class="ml-menu" *ngIf="sidebarItem.title==='Proyectos' && currentUserRol==='ROLE_ADMIN'">
            <li>
              <a class="submenu" (click)="downloadReport()" ngClass="ml-menu">
                <i style="font-size: 17px;" ngClass="material-icons-two-tone">library_books</i>
                <span class="text-item">Reportes</span>
              </a>
            </li>

          </ul>

        </li>
        <li style="cursor: pointer;">
          <a (click)="logout()" class="menu-top">
            <i style="font-size: 17px;" class="material-icons-two-tone">power_settings_new</i>
            <span class="hide-menu text-item">Cerrar sesión
            </span>
          </a>
        </li>
        <!-- <div> -->

        <!-- </div> -->
      </ul>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>
